import ApplicationController from './application_controller.js'

export default class extends ApplicationController {
  remove_section(event) {
    event.preventDefault();

    let target = event.target;
    let controller = this;

    $.ajax({
      url: '/users/swal_translations',
      success: function(res){
        let translations = res.confirm_delete;

        Swal.fire({
          heightAuto: false,
          title: translations.title,
          text: translations.text,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: 'var(--danger)',
          cancelButtonColor: 'var(--muted)',
          confirmButtonText: '<i class="fas fa-trash-alt"></i> ' + translations.button_ok,
          cancelButtonText: '<i class="fas fa-undo-alt"></i> ' + translations.button_cancel
        }).then((result) => {
          if (result.isConfirmed) {
            controller.stimulate('PageConfig#remove_section', target);
            // Swal.fire(
            //   'Deleted!',
            //   'Your file has been deleted.',
            //   'success'
            // );
          }
        });
      }
    });

  }

  update_component(event) {
    event.preventDefault();
    this.stimulate('PageConfig#update_component', event.target)
    .then(function(result){
      toastr.options.positionClass = "toast-bottom-right";
      toastr.options.timeOut = 1000;
      toastr.success('Salvato')
    });
  }

  remove_component(event) {
    event.preventDefault();

    let target = event.target.closest('a'); // fontawesome nested svg
    let controller = this;

    $.ajax({
      url: '/users/swal_translations',
      success: function(res){
        let translations = res.confirm_delete;

        Swal.fire({
          heightAuto: false,
          title: translations.title,
          text: translations.text,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: 'var(--danger)',
          cancelButtonColor: 'var(--muted)',
          confirmButtonText: '<i class="fas fa-trash-alt"></i> ' + translations.button_ok,
          cancelButtonText: '<i class="fas fa-undo-alt"></i> ' + translations.button_cancel
        }).then((result) => {
          if (result.isConfirmed) {
            controller.stimulate('PageConfig#remove_component', target);
            // Swal.fire(
            //   'Deleted!',
            //   'Your file has been deleted.',
            //   'success'
            // );
          }
        });
      }
    });

  }
}