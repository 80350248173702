// caricamento lingue
const datatableIt = require('init/datatables/datatable.it.json')
const datatableEn = require('init/datatables/datatable.en.json')

// inizializzazione
$.extend(true, $.fn.dataTable.defaults, {
  autoWidth: false,
  lengthMenu: [ 10, 20, 40, 80 ],
  dom: 'ltipr',
  processing: true,
  serverSide: true,
  language: localStorage.getItem('locale') == 'it' ? datatableIt : datatableEn,
  pagingType: 'first_last_numbers', // numbers | simple | simple_numbers | full | full_numbers | first_last_numbers
})
