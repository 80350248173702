import Swal from 'sweetalert2'
import Rails from '@rails/ujs'

window.Swal = Swal

// Behavior after click to confirm button
const confirmed = (element, result, action, translations, url) => {
  if (result.value) {
    if (action == 'delete') {
      $.ajax({
        method: 'DELETE',
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        url: url + '?format=json',
        success: function (data) {
          if (data.deleted_at && $('.dataTable').length) {
            // Reload table data
            $('.dataTable').DataTable().ajax.reload();
            Swal.fire({
              title: translations.success,
              icon: 'success',
              confirmButtonText: '<i class="far fa-times-circle"></i>' + translations.button_close,
              confirmButtonColor: 'var(--primary)',
              confirmButtonAriaLabel: translations.button_close
            })
          } else {
            location.reload()
          }
        }
      });
    } else {
      Swal.fire({
        title: translations.success,
        icon: 'success',
        confirmButtonText: '<i class="far fa-times-circle"></i>' + translations.button_close,
        confirmButtonColor: 'var(--primary)',
        confirmButtonAriaLabel: translations.button_close
      })
    }
  } else {
    Swal.fire({
      title: translations.canceled,
      icon: 'error',
      confirmButtonText: '<i class="far fa-times-circle"></i>' + translations.button_close,
      confirmButtonColor: 'var(--primary)',
      confirmButtonAriaLabel: translations.button_close
    });
  }
};

// Display the confirmation dialog
const showConfirmationDialog = (element, extAction = null, extUrl = null) => {
  const message = element.getAttribute('data-confirm-swal')
  const text = element.getAttribute('data-text')
  const icon = element.getAttribute('data-icon') // success, error, warning, info, question
  const action = extAction || element.getAttribute('data-action')
  const url = extUrl || element.getAttribute('href')

  $.ajax({
    method: 'GET',
    url: '/users/swal_translations?format=json',
    success: function (data) {
      if (data.length != 0) {
        let translations = null
        let confirmButtonIcon = null
        let confirmButtonColor = null
        if (data.confirm_delete && action == 'delete') {
          translations = data.confirm_delete
          confirmButtonIcon = '<i class="fas fa-trash-alt"></i>'
          confirmButtonColor = 'var(--danger)'
        } else {
          translations = data.default
          confirmButtonIcon = '<i class="fas fa-check"></i>'
          confirmButtonColor = 'var(--primary)'
        }

        Swal.fire({
          title: message || translations.title,
          text: text || translations.text,
          icon: icon || 'question',
          showCancelButton: true,
          confirmButtonText: confirmButtonIcon + ' ' + translations.button_ok,
          confirmButtonColor: confirmButtonColor,
          confirmButtonAriaLabel: translations.button_ok,
          cancelButtonText: '<i class="fas fa-undo-alt"></i>' + translations.button_cancel,
          cancelButtonColor: 'var(--muted)',
          cancelButtonAriaLabel: translations.button_cancel
        }).then(result => confirmed(element, result, action, translations, url))
      }
    }
  })
}

const allowAction = (element) => {
  if (element.getAttribute('data-confirm-swal') === null) {
    return true
  }

  showConfirmationDialog(element)
  return false
}

function handleConfirm(element) {
  if (!allowAction(this)) {
    Rails.stopEverything(element);
  }
}

Rails.delegate(document, 'a[data-confirm-swal]', 'click', handleConfirm)

// Display the cannot dialog
const showCannotDialog = (element) => {
  const message = element.getAttribute('data-cannot-swal')
  const text = element.getAttribute('data-text')
  const icon = element.getAttribute('data-icon') // success, error, warning, info, question
  const action = element.getAttribute('data-action')
  const code = element.getAttribute('data-code')

  $.ajax({
    method: 'GET',
    url: '/users/swal_translations?format=json',
    success: function (data) {
      if (data.length != 0) {
        let translations = null
        let confirmButtonIcon = '<i class="far fa-times-circle"></i>'
        if (data.cannot && action && data.cannot[action]) {
          translations = data.cannot[action][code]
        } else {
          translations = data.default
        }

        Swal.fire({
          title: message || translations.title,
          text: text || translations.text,
          icon: icon || 'warning',
          confirmButtonText: confirmButtonIcon + ' ' + translations.button_close,
          confirmButtonColor: 'var(--primary)',
          confirmButtonAriaLabel: translations.button_close
        })
      }
    }
  })
}

const allowCannot = (element) => {
  if (element.getAttribute('data-cannot-swal') === null) {
    return true
  }

  showCannotDialog(element)
  return false
}

function handleCannotDo(element) {
  if (!allowCannot(this)) {
    Rails.stopEverything(element);
  }
}

Rails.delegate(document, 'a[data-cannot-swal]', 'click', handleCannotDo)